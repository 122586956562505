import axios from 'axios'
import router from '@/router'

export default {
    namespaced: true,
    state: {
        retjenis1Options: [],
        retjenis2Options: [],
        retjenis3Options: [],
        rettarifOptions: []
    },
    mutations: {
        inputRetJenis1 (state, data) {
            state.retjenis1Options = data
        },
        inputRetJenis2 (state, data) {
            state.retjenis2Options = data
        },
        inputRetJenis3 (state, data) {
            state.retjenis3Options = data
        },
        inputRetTarif (state, data) {
            state.rettarifOptions = data
        },
    },
    actions: {
        async dataRetJenis1 ({ commit }, id) {
           try {
               let response = await axios.get("/api/retribusi/ret-jenis-1/getoption/perangkat-pd-" + id)
               console.log(response.data.data)
               commit("inputRetJenis1", response.data.data)
           } catch (error) {
               console.log(error)
               if (error.response.status === 401) {
                    localStorage.clear()
                    router.push({ name: 'Login' })
                }
           }
        },
        async dataRetJenisRefRek6 ({ commit }, id) {
            try {
                let response = await axios.get("/api/retribusi/ret-jenis-1/tiket-masuk/getoption/perangkat-pd-" + id)
                console.log(response.data.data)
                commit("inputRetJenis1", response.data.data)
            } catch (error) {
                console.log(error)
                if (error.response.status === 401) {
                     localStorage.clear()
                     router.push({ name: 'Login' })
                 }
            }
         },
        async dataRetJenis2 ({ commit }, id) {
            try {
                let response = await axios.get("/api/retribusi/ret-jenis-2/getoption/ret-jenis-1-" + id)
                commit("inputRetJenis2", response.data.data)
            } catch (error) {
                console.log(error)
                if (error.response.status === 401) {
                    localStorage.clear()
                    router.push({ name: 'Login' })
                }
            }
         },
         async dataRetJenis3 ({ commit }, id) {
            try {
                let response = await axios.get("/api/retribusi/ret-jenis-3/getoption/ret-jenis-2-" + id)
                commit("inputRetJenis3", response.data.data)
            } catch (error) {
                console.log(error)
                if (error.response.status === 401) {
                    localStorage.clear()
                    router.push({ name: 'Login' })
                }
            }
         },
         async dataRetTarif ({ commit }, id) {
            try {
                let response = await axios.get("/api/retribusi/ret-tarif/getoption/ret-jenis-3-" + id)
                commit("inputRetTarif", response.data.data)
            } catch (error) {
                console.log(error)
                if (error.response.status === 401) {
                    localStorage.clear()
                    router.push({ name: 'Login' })
                }
            }
         }
    }
}