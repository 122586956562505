<template>
	<div class="fade page-sidebar-fixed page-header-fixed show page-container" v-if="!pageOptions.pageEmpty"
		v-bind:class="{ 
		'page-sidebar-minified': pageOptions.pageSidebarMinified, 
		'page-content-full-height': pageOptions.pageContentFullHeight, 
		'page-without-sidebar': pageOptions.pageWithoutSidebar, 
		'page-with-right-sidebar': pageOptions.pageWithRightSidebar, 
		'page-with-two-sidebar': pageOptions.pageWithTwoSidebar,
		'page-with-wide-sidebar': pageOptions.pageWithWideSidebar,
		'page-with-light-sidebar': pageOptions.pageWithLightSidebar,
		'page-with-top-menu': pageOptions.pageWithTopMenu,
		'page-sidebar-toggled': pageOptions.pageMobileSidebarToggled,
		'page-right-sidebar-toggled': pageOptions.pageMobileRightSidebarToggled || pageOptions.pageRightSidebarToggled,
		'page-right-sidebar-collapsed': pageOptions.pageRightSidebarCollapsed,
		'has-scroll': pageOptions.pageBodyScrollTop
	}">
		<Header />
		<TopMenu v-if="pageOptions.pageWithTopMenu" />
		<Sidebar v-if="!pageOptions.pageWithoutSidebar" />
		<SidebarRight v-if="pageOptions.pageWithTwoSidebar" />
		<div id="content" class="content"
			v-bind:class="{ 'content-full-width': pageOptions.pageContentFullWidth, 'content-inverse-mode': pageOptions.pageContentInverseMode }">
			<router-view></router-view>
			<vue-ins-progress-bar></vue-ins-progress-bar>
		</div>
		<Footer v-if="pageOptions.pageWithFooter" />
		<b-toast id="notifToast" variant="success" solid auto-hide-delay="6000">
			<template #toast-title>
				<div class="d-flex flex-grow-1 align-items-baseline">
				<b-img blank blank-color="#ff5555" class="mr-2" width="12" height="12"></b-img>
				<strong class="mr-auto">Berhasil!</strong>
				</div>
			</template>
			Pembayaran dengan <br> No. SPT: {{ no_spt }} telah divalidasi.
		</b-toast>
	</div>
	<div class="h-100" v-else>
		<router-view></router-view>
		<vue-ins-progress-bar></vue-ins-progress-bar>
	</div>
</template>

<script>
	import Sidebar from './components/sidebar/Sidebar.vue'
	import SidebarRight from './components/sidebar-right/SidebarRight.vue'
	import Header from './components/header/Header.vue'
	import TopMenu from './components/top-menu/TopMenu.vue'
	import Footer from './components/footer/Footer.vue'
	import PageOptions from './config/PageOptions.vue'

	export default {
		name: 'app',
		components: {
			Sidebar,
			SidebarRight,
			Header,
			TopMenu,
			Footer
		},
		data() {
			return {
				pageOptions: PageOptions,
				role: null,
				pegawai: null,
				no_spt: 0
			}
		},
		methods: {
			handleScroll: function () {
				PageOptions.pageBodyScrollTop = window.scrollY;
			},
			channelPerangkat: function (perangkat_pd_id) {
				window.Echo.private(`bukti-setor-pd.${perangkat_pd_id}`).listen('BuktiSetorEvent', response => {
					if (response) {
						this.no_spt = response.data.no_spt
						this.$bvToast.show('notifToast')
					}
				})
			},
			channelUnit: function (unit_pd_id) {
				window.Echo.private(`bukti-setor-unit.${unit_pd_id}`).listen('BuktiSetorEvent', response => {
					this.no_spt = response.data.no_spt
					this.$bvToast.show('notifToast')
				})
			}
		},
		mounted() {
			this.$insProgress.finish()
			// set roles vue gates 
			this.$gates.setRoles(JSON.parse(localStorage.getItem('roles')))
			// this.$bvToast.show('notifToast')
		},
		created() {
			PageOptions.pageBodyScrollTop = window.scrollY;

			window.addEventListener('scroll', this.handleScroll);

			this.$insProgress.start()

			this.$router.beforeEach((to, from, next) => {
				this.$insProgress.start()
				next()
			})
			this.$router.afterEach(() => {
				this.$insProgress.finish()
			})
			this.role = JSON.parse(localStorage.getItem('roles'))[0]
			this.pegawai = JSON.parse(localStorage.getItem('pegawai'))
			if (['Administrator', 'Bendahara'].includes(this.role)) {
				this.channelPerangkat(this.pegawai.unit_pd.perangkat_pd_id)
			} else {
				this.channelUnit(this.pegawai.unit_pd_id)
			}
		}
	}
</script>