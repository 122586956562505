import axios from 'axios'

export default {
    namespaced: true,
    state: {
        ret_jenis_1: [],
        ret_jenis_2: [],
        ret_jenis_3: []
    },
    mutations: {
        dataRetJenis1 (state, data) {
            state.ret_jenis_1 = data
        },
        dataRetJenis2 (state, data) {
            state.ret_jenis_2 = data
        },
        dataRetJenis3 (state, data) {
            state.ret_jenis_3 = data
        }
    },
    actions: {
        getRetJenis1 ({ commit }) {
            axios.get("/api/retribusi/ret-jenis-1/getoption").then(response => {
                commit("dataRetJenis1", response.data.data)
            }).catch(error => {
                console.log(error)
            })
        },
        getRetJenis2 ({ commit }) {
            axios.get("/api/retribusi/ret-jenis-2/getoption").then(response => {
                commit("dataRetJenis2", response.data.data)
            }).catch(error => {
                console.log(error)
            })
        },
        getRetJenis3 ({ commit }) {
            axios.get("/api/retribusi/ret-jenis-3/getoption").then(response => {
                commit("dataRetJenis3", response.data.data)
            }).catch(error => {
                console.log(error)
            })
        }
    }
}