import axios from 'axios'

export default {
    namespaced: true,
    state: {
        refRek5: [],
        refRek6: [],
    },
    mutations: {
        inputRefRek5 (state, data) {
            state.refRek5 = data
        },
        inputRefRek6 (state, data) {
            state.refRek6 = data
        }
    },
    actions: {
        getRefRek5 ({ commit }) {
            axios.get("/api/referensi/rekening-5/getoption").then(response => {
                commit("inputRefRek5", response.data.data)
            }).catch(error => {
                console.log(error)
            })
        },
        getRefRek6 ({ commit }, kode) {
            axios.get("/api/referensi/rekening-6/getoption/rekening-5-" + kode).then(response => {
                commit("inputRefRek6", response.data.data)
            }).catch(error => {
                console.log(error)
            })
        }
    }
}