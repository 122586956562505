import axios from 'axios'
import router from '@/router'

export default {
    namespaced: true,
    state: {
        provinsi: [],
        kabkota: [],
        kecamatan: [],
        kelurahan: []
    },
    mutations: {
        provinsi (state, data) {
            state.provinsi = data
        },
        kabkota (state, data) {
            state.kabkota = data
        },
        kecamatan (state, data) {
            state.kecamatan = data
        },
        kelurahan (state, data) {
            state.kelurahan = data
        }
    },
    actions: {
        // provinsi
        getProvinsi ({commit}) {
            axios.get("/api/data-induk/wilayah-indonesia/provinsi/getoption").then(response => {
                commit("provinsi", response.data.data)
            }).catch(error => {
                console.log(error)
                if (error.response.status === 401) {
                    localStorage.clear()
                    router.push({ name: 'Login' })
                }
            })
        },
        // kabkota
        getKabkota ({commit}, id) {
            axios.get("/api/data-induk/wilayah-indonesia/kabkota/getoption/provinsi-" + id).then(response => {
                commit("kabkota", response.data.data)
            }).catch(error => {
                console.log(error)
                if (error.response.status === 401) {
                    localStorage.clear()
                    router.push({ name: 'Login' })
                }
            })
        },
        // kecamatan
        getKecamatan ({commit}, id) {
            axios.get("/api/data-induk/wilayah-indonesia/kecamatan/getoption/kabkota-" + id).then(response => {
                commit("kecamatan", response.data.data)
            }).catch(error => {
                console.log(error)
                if (error.response.status === 401) {
                    localStorage.clear()
                    router.push({ name: 'Login' })
                }
            })
        },
        // kelurahan
        getKelurahan ({commit}, id) {
            axios.get("/api/data-induk/wilayah-indonesia/kelurahan/getoption/kecamatan-" + id).then(response => {
                commit("kelurahan", response.data.data)
            }).catch(error => {
                console.log(error)
                if (error.response.status === 401) {
                    localStorage.clear()
                    router.push({ name: 'Login' })
                }
            })
        }
    },
    getters: {
        penanggung_jawab: state => {
            return state.kabkota
        },
        identitas: state => {
            return state.kabkota
        }
    }
}