import axios from 'axios'

export default {
    namespaced: true,
    state: {
        urusan: [],
        klasifikasi: [],
        perangkat: []
    }, 
    mutations: {
        inputUrusan (state, data) {
            state.urusan = data
        },
        inputKlasifikasi (state, data) {
            state.klasifikasi = data
        },
        inputPerangkat (state, data) {
            state.perangkat = data
        }
    },
    actions: {
        getUrusan ({ commit }) {
            axios.get("/api/pemerintah-daerah/urusan-pd/getoption").then(response => {
                commit('inputUrusan', response.data.data)
            }).catch(error => {
                console.log(error)
            })
        },
        getKlasifikasi ({ commit }, id) {
            axios.get("/api/pemerintah-daerah/klasifikasi-pd/getoption/urusan-pd-" + id).then(response => {
                commit('inputKlasifikasi', response.data.data)
            }).catch(error => {
                console.log(error)
            })
        },
        getPerangkat ({ commit }) {
            axios.get('/api/pemerintah-daerah/perangkat-pd/getoption').then(response => {
                commit('inputPerangkat', response.data.data)
            }).catch(error => {
                console.log(error)
            })
        }
    }
}