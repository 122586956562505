import axios from 'axios'
import router from '@/router/'

export default {
    namespaced: true,
    state: {
        bentuk_badan: []
    },
    mutations: {
        dataBentukBadan (state, data) {
            state.bentuk_badan = data
        }
    },
    actions: {
        bentukBadan ({ commit }) {
            axios.get("/api/wajib-retribusi/bentuk-badan/getoption").then(response => {
                commit('dataBentukBadan', response.data.data)
            }).catch(error => {
                console.log(error)
                if (error.response.status === 401) {
                    localStorage.clear()
                    router.push({ name: 'Login' })
                }
            })
        }
    }
}
