import axios from 'axios'

export default {
    namespaced: true,
    state: {
        pegawai: []
    },
    mutations: {
        inputPegawai (state, data) {
            state.pegawai = data
        }
    },
    actions: {
        getAllPegawai ({ commit }) {
            axios.get("/api/data-induk/kepegawaian/pegawai/getoption").then(response => {
                commit("inputPegawai", response.data.data)
            }).catch(error => {
                console.log(error)
            })
        }   
    }
}