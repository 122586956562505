<script>
const sidebarMenu = [
    {
        path: "/dashboard",
        icon: "fa fa-home",
        title: "BERANDA",
        haveAccess: [
            "Maintenance",
            "Administrator",
            "Operasional",
            "Pimpinan",
            "Bendahara",
            "Wajib Retribusi"
        ],
        children: [
            {
                path: "/dashboard",
                title: "Beranda",
                haveAccess: [
                    "Maintenance",
                    "Administrator",
                    "Operasional",
                    "Pimpinan",
                    "Bendahara",
                    "Wajib Retribusi"
                ],
            },
        ],
    },
    {
        path: "/menu",
        icon: "fa fa-info-circle",
        title: "MENU",
        haveAccess: [
            "Maintenance",
            "Administrator",
            "Operasional",
            "Pimpinan",
            "Bendahara",
            "Wajib Retribusi"
        ],
        children: [
            {
                path: "/menu/npwrd/list",
                title: "NPWR Daerah \n Wajib Retribusi",
                haveAccess: [
                    "Maintenance",
                    "Administrator",
                    "Operasional",
                    "Pimpinan",
                    "Bendahara",
                ],
            },
            {
                path: "/menu/sptrd/pendaftaran",
                title: "Buat SPTR Daerah \n SPT Retribusi Daerah",
                haveAccess: [
                    "Maintenance",
                    "Administrator",
                    "Operasional",
                    "Pimpinan",
                    "Bendahara",
                ],
            },
            {
                path: "/menu/sptrd/tiket",
                title: "Buat Tiket Masuk \n SPT Retribusi Daerah",
                haveAccess: [
                    "Maintenance",
                    "Administrator",
                    "Operasional",
                    "Pimpinan",
                    "Bendahara",
                ],
            },
            // {
            //     path: "/menu/strd1/",
            //     title: "Buat STR Daerah \n SPT Retribusi Daerah",
            //     haveAccess: ["Operasional", "Bendahara"],
            //     children: [
            //         {
            //             path: "/menu/strd2/",
            //             title: "Retribusi Pemakaian \n Kekayaan Daerah",
            //             haveAccess: ["Operasional", "Bendahara"],
            //         },
            //         {
            //             path: "/menu/strd3/",
            //             title: "Retribusi Penjualan \n Produksi Usaha Daerah",
            //             haveAccess: ["Operasional", "Bendahara"],
            //         },
            //         {
            //             path: "/menu/strd4/",
            //             title: "Retribusi Izin Usaha \n Perikanan",
            //             haveAccess: ["Operasional", "Bendahara"],
            //         },
            //     ],
            // },
            {
                path: "/menu/sptrd/list",
                title: "List SPTR Daerah \n SPT Retribusi Daerah",
                haveAccess: [
                    "Maintenance",
                    "Administrator",
                    "Operasional",
                    "Pimpinan",
                    "Bendahara",
                    "Wajib Retribusi"
                ],
            },
            {
                path: "/menu/kode-bayar/buat",
                title: "Buat Kode Bayar",
                haveAccess: [
                    "Maintenance",
                    "Administrator",
                    // "Operasional",
                    // "Pimpinan",
                    // "Bendahara",
                ],
            },
            {
                path: "/menu/kode-bayar/list",
                title: "List Kode Bayar",
                haveAccess: [
                    "Maintenance",
                    "Administrator",
                    // "Operasional",
                    // "Pimpinan",
                    // "Bendahara",
                ],
            },
            {
                path: "/menu/ssrd/buat",
                title: "Buat SSR Daerah \n SS Retribusi Daerah",
                haveAccess: ["Maintenance", "Administrator", "Bendahara"],
            },
            {
                path: "/menu/ssrd/list",
                title: "List SSR Daerah \n SS Retribusi Daerah",
                haveAccess: ["Maintenance", "Administrator", "Bendahara"],
            },
            // {
            //     path: "/menu/ssrd/pembayaran-cash",
            //     title: "SSRD \n Pembayaran Melalui \n Bendahara Penerimaan",
            //     haveAccess: [
            //         "Maintenance",
            //         "Administrator",
            //         "Operasional",
            //         "Bendahara",
            //     ],
            // },
            {
                path: "/menu/stsrd/buat",
                title: "Buat STSR Daerah \n STS Retribusi Daerah",
                haveAccess: ["Maintenance", "Administrator", "Bendahara"],
            },
            {
                path: "/menu/stsrd/list",
                title: "List STSR Daerah \n STS Retribusi Daerah",
                haveAccess: ["Maintenance", "Administrator", "Bendahara"],
            },
        ],
    },
    {
        path: "/pelaporan/laporan",
        title: "LAPORAN",
        icon: "fa fa-chart-line",
        haveAccess: ["Maintenance", "Administrator", "Bendahara"],
        children: [
            {
                path: '/transaksi',
                title: 'TRANSAKSI',
                haveAccess: ["Maintenance", "Administrator", "Bendahara"],
                children: [
                    {
                        path: "/pelaporan/transaksi/skrd",
                        title: "Laporan SKRD",
                        haveAccess: ["Maintenance", "Administrator", "Bendahara"],
                    },
                    {
                        path: "/pelaporan/transaksi/ssrd",
                        title: "Laporan SSRD",
                        haveAccess: ["Maintenance", "Administrator", "Bendahara"],
                    },
                ]
            },
            // {
            //     path: "/pelaporan/transaksi/spt/skrd-filter",
            //     title: "Laporan SKRD",
            //     haveAccess: ["Maintenance", "Administrator", "Bendahara"],
            // },
            
            // {
            //     path: "/pelaporan/transaksi/ssrd-filter",
            //     title: "Laporan SSRD\n Realisasi Penerimaan",
            //     haveAccess: ["Maintenance", "Administrator", "Bendahara"],
            // },
            {
                path: '/realisasi',
                title: 'REALISASI',
                haveAccess: ["Maintenance", "Administrator", "Bendahara"],
                children: [
                    {
                        path: "/pelaporan/transaksi/realisasi-penerimaan",
                        title: "Lap. Penerimaan",
                        haveAccess: ["Maintenance", "Administrator", "Bendahara"],
                    },
                    {
                        path: "/pelaporan/transaksi/realisasi-penerimaan-item",
                        title: "Lap. Penerimaan \n Per Item",
                        haveAccess: ["Maintenance", "Administrator", "Bendahara"],
                    },
                ]
            }
        ],
    },
    {
        path: "/pustaka",
        icon: "fa fa-file-alt",
        title: "PUSTAKA",
        haveAccess: ["Maintenance", "Administrator", "Bendahara"],
        children: [
            {
                path: "/pustaka/tarif-retribusi",
                title: "Tarif Retribusi",
                haveAccess: ["Maintenance", "Administrator", "Bendahara"],
            },
        ],
    },

    // ==================================================
    // Data Induk
    // --------------------------------------------------
    // /data-induk/...
    // {
    //     path: "/data-induk",
    //     icon: "fa fa-university",
    //     title: "JDIH",
    //     haveAccess: ["Administrator"],
    //     children: [
            {
                path: "/data-induk/jdih",
                icon: "fa fa-university",
                title: "JDIH",
                haveAccess: ["Administrator", "Maintenance"],
                children: [
                    {
                        path: "/data-induk/jdih/jenis-hukum",
                        title: "Jenis Hukum",
                        haveAccess: ["Administrator", "Maintenance"],
                    },
                    {
                        path: "/data-induk/jdih/produk-hukum",
                        title: "Produk Hukum",
                        haveAccess: ["Administrator", "Maintenance"],
                    },
                ],
            },
            {
                path: "/data-induk/apbd",
                icon: "fa fa-university",
                title: "APBD",
                haveAccess: ["Administrator", "Maintenance"],
                children: [
                    {
                        path: "/data-induk/apbd/target",
                        title: "Target",
                        haveAccess: ["Administrator", "Maintenance"],
                    },
                ],
            },
    //     ]
    // },

    {
        path: "/kepegawaian",
        icon: "fa fa-address-book",
        title: "KEPEGAWAIAN",
        haveAccess: ["Maintenance", "Administrator"],
        children: [
            {
                path: "/kepegawaian/jabatan",
                title: "Jabatan",
                haveAccess: ["Administrator", "Maintenance"],
            },
            {
                path: "/kepegawaian/pangkat",
                title: "Pangkat",
                haveAccess: ["Administrator", "Maintenance"],
            },
            {
                path: "/kepegawaian/pegawai",
                title: "Pegawai",
                haveAccess: ["Administrator", "Maintenance"],
            },
        ],
    },
    {
        path: "/pemerintah-daerah",
        icon: "fa fa-university",
        title: "PEMERINTAH DAERAH",
        haveAccess: ["Maintenance", "Administrator"],
        children: [
            {
                path: "/pemerintah-daerah/urusan",
                title: "Urusan \n Pemerintah Daerah",
                haveAccess: ["Administrator", "Maintenance"],
            },
            {
                path: "/pemerintah-daerah/klasifikasi",
                title: "Klasifikasi \n Pemerintah Daerah",
                haveAccess: ["Administrator", "Maintenance"],
            },
            {
                path: "/pemerintah-daerah/perangkat",
                title: "Perangkat \n Pemerintah Daerah",
                haveAccess: ["Administrator", "Maintenance"],
            },
            {
                path: "/pemerintah-daerah/unit",
                title: "Unit \n Pemerintah Daerah",
                haveAccess: ["Administrator", "Maintenance"],
            },
        ],
    },
    {
        path: "/wilayah",
        icon: "fa fa-map-marker-alt",
        title: "WILAYAH ADMINISTRATIF",
        haveAccess: ["Maintenance", "Administrator"],
        children: [
            {
                path: "/wilayah/provinsi",
                title: "Provinsi",
                haveAccess: ["Administrator", "Maintenance"],
            },
            {
                path: "/wilayah/kabkota",
                title: "Kab./Kota",
                haveAccess: ["Administrator", "Maintenance"],
            },
            {
                path: "/wilayah/kecamatan",
                title: "Kecamatan",
                haveAccess: ["Administrator", "Maintenance"],
            },
            {
                path: "/wilayah/kelurahan",
                title: "Kelurahan",
                haveAccess: ["Administrator", "Maintenance"],
            },
        ],
    },
    {
        path: "/retribusi",
        icon: "fa fa-truck",
        title: "RETRIBUSI",
        haveAccess: ["Administrator"],
        children: [
            {
                path: "/retribusi/ret-jenis-1",
                title: "Jenis Retribusi 1",
                haveAccess: ["Administrator"],
            },
            {
                path: "/retribusi/ret-jenis-2",
                title: "Jenis Retribusi 2",
                haveAccess: ["Administrator"],
            },
            {
                path: "/retribusi/ret-jenis-3",
                title: "Jenis Retribusi 3",
                haveAccess: ["Administrator"],
            },
            {
                path: "/retribusi/tarif",
                title: "Tarif Retribusi",
                haveAccess: ["Administrator"],
            },
        ],
    },

    // ==================================================
    // PENGATURAN
    // --------------------------------------------------
    // Profil Pengguna
    {
        path: "/pengaturan/profil",
        title: "PROFIL PENGGUNA",
        icon: "fa fa-user",
        haveAccess: ["Bendahara", "Operasional", "Pimpinan" ],
    },

    {
        path: "/pengaturan/pengguna",
        title: "SEMUA PENGGUNA",
        icon: "fa fa-users",
        haveAccess: ["Administrator", "Maintenance"],
    },
    {
        path: "/pengaturan/hak-akses",
        icon: "fa fa-lock-open",
        title: "HAK AKSES",
        haveAccess: ["Maintenance", "Administrator"],
        children: [
            {
                path: "/pengaturan/hak-akses/role",
                title: "Role",
                haveAccess: ["Administrator", "Maintenance"],
            },
            {
                path: "/pengaturan/hak-akses/permission",
                title: "Permission",
                haveAccess: ["Administrator", "Maintenance"],
            },
        ],
    },
    // --------------------------------------------------
    // Pengaturan Status
    {
        path: "/pengaturan/aplikasi",
        icon: "fa fa-laptop",
        title: "APLIKASI",
        haveAccess: ["Maintenance", "Administrator"],
        children: [
            {
                path: "/pengaturan/aplikasi/status-aplikasi",
                title: "Status Aplikasi",
                haveAccess: ["Administrator", "Maintenance"],
            },
        ],
    },
    // --------------------------------------------------
    // Profil Wajib Retribusi
    {
        path: "/profil",
        icon: "fa fa-user",
        title: "Profil",
        haveAccess: ["Wajib Retribusi"],
        children: [
            {
                path: "/profil/wajib-retribusi",
                title: "Detail Wajib Retribusi",
                haveAccess: ["Wajib Retribusi"],
            },
        ]
    },
    // {
    //     path: "/users",
    //     icon: "fa fa-users",
    //     title: "MANAGEMENT USERS",
    //     haveAccess: ["Maintenance", "Administrator"],
    //     children: [
    //         {
    //             path: "/users/modul",
    //             title: "Modul Retribusi",
    //             haveAccess: ["Maintenance", "Administrator"],
    //         },
    //         {
    //             path: "/users/level",
    //             title: "Level Users",
    //             haveAccess: ["Maintenance", "Administrator"],
    //         },
    //         {
    //             path: "/users/users",
    //             title: "List Users",
    //             haveAccess: ["Maintenance", "Administrator"],
    //         },
    //         // { path: '/users/users-detail', title: 'List Pengguna Users ID', haveAccess: ['Maintenance', 'Administrator'] },
    //         {
    //             path: "/users/password",
    //             title: "Ganti Password",
    //             haveAccess: ["Maintenance", "Administrator"],
    //         },
    //         {
    //             path: "/users/user-history",
    //             title: "List History User",
    //             haveAccess: ["Maintenance", "Administrator"],
    //         },
    //     ],
    // },
];

export default sidebarMenu;
</script>
