import Vue from 'vue'
import Vuex from 'vuex'
import WilayahIndonesia from './wilayah/wilayah_indonesia'
import BentukBadan from './bentuk_badan/bentuk_badan.js'
import Jabatan from './jabatan/jabatan.js'
import Step from './pendaftaran/pendaftaran'
import cekNpwrd from './cekNpwrd/cekNpwrd'
import Pendaftaran from './pendaftaran/pendaftaran'
import StepRetribusi from './step_retribusi/step_retribusi'
import Sptrd from './sptrd/sptrd'
import Perangkat from './perangkat/perangkat'
import Kepegawaian from './kepegawaian/kepegawaian'
import PemerintahDaerah from './pemerintah_daerah/pemerintah_daerah'
import Role from "./role/role"
import RefRek from "./ref_rekening/ref_rekening"
import WilayahReg from "./wilayah/wilayah_reg"
import PerangkatReg from "./perangkat/perangkatReg"
import StepWajib from "./step_wajib_ret/step_wajib_ret"
import Retribusi from "./retribusi/retribusi"
import StatusAplikasi from './status_aplikasi/status_aplikasi'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    WilayahIndonesia,
    BentukBadan,
    Jabatan,
    Step,
    cekNpwrd,
    Pendaftaran,
    StepRetribusi,
    Sptrd,
    Perangkat,
    Kepegawaian,
    PemerintahDaerah,
    Role,
    RefRek,
    WilayahReg,
    PerangkatReg,
    StepWajib,
    Retribusi,
    StatusAplikasi
  }
})