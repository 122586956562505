export default {
    namespaced: true,
    state: {
        isDisable: false,
        isDisable2: false,
        isDisable3: false,
        disableBentuk: false
    },
    mutations: {
        exeDisable (state, value) {
            state.isDisable = value
        },
        exeDisable2 (state, value) {
            state.isDisable2 = value
        },
        exeDisable3 (state, value) {
            state.isDisable3 = value
        },
        exeDisableBentuk (state, value) {
            state.disableBentuk = value
        }
    },
    actions: {
        isEventDisable ({commit}, value) {
            commit('exeDisable', value)
        },
        isEventDisable2 ({commit}, value) {
            commit('exeDisable2', value)
        },
        isEventDisable3 ({commit}, value) {
            commit('exeDisable3', value)
        },
        isDisableBentuk ({commit}, value) {
            commit('exeDisableBentuk', value)
        }
    }
}