import axios from 'axios'
import router from '@/router/'

export default {
    namespaced: true,
    state: {
        detailNpwrd: [],
        dataNpwrd: [],
        dataExist: false,
        dataExist2: false,
        isLoading: false,
        isLoading2: false,
        next: true,
        prevMode: false
    },
    mutations: {
        dataNpwrd (state, data) {
            state.detailNpwrd = data
        },
        inputDataNpwrd (state, data) {
            state.dataNpwrd = data
        },
        existNpwrd(state, value) {
            if (value.name === 'pokok') {
                state.dataExist = value.bol
            } else if (value.name === 'induk') {
                state.dataExist2 = value.bol
            }
        },
        isLoading(state, value) {
            if (value.name === 'pokok') {
                state.isLoading = value.bol
            } else if (value.name === 'induk') {
                state.isLoading2 = value.bol
            }
        },
        isNext(state, value) {
            state.next = value
        },
        isPrev(state, value) {
            state.prevMode = value
        }

    },
    actions: {
        async detail ({ commit }, nomor) {
            commit('isLoading', {bol: true, name: 'pokok'})
            try {
                let response = await axios.get("/api/wajib-retribusi/npwrd/nomor-pokok-" + nomor)
                commit('isLoading', {bol: false, name: 'pokok'})
                commit('dataNpwrd', response.data.data)
                commit('existNpwrd', {bol: false, name: 'pokok'})
                commit('isNext', false)
            } catch (error) {
                commit('isLoading', {bol: false, name: 'pokok'})
                commit('isNext', true)
                if (error.response.status === 404) {
                    commit('dataNpwrd', [])
                    commit('existNpwrd', {bol: true, name: 'pokok'})
                } else if (error.response.status === 401) {
                    localStorage.clear()
                    router.push({ name: 'Login' })
                }
            }
        },
        async npwrdData ({ commit }, id) {
            try {
                let response = await axios.get("/api/wajib-retribusi/npwrd/" + id)
                commit('inputDataNpwrd', response.data.data)
            } catch (error) {
                console.log(error)
            }
        },
        async detailInduk ({commit}, nomor) {
            commit('isLoading', {bol: true, name: 'induk'})
            try {
                let response = await axios.get("/api/wajib-retribusi/npwrd/nomor-induk-" + nomor)
                commit('isLoading', {bol: false, name: 'induk'})
                commit('dataNpwrd', response.data.data)
                commit('existNpwrd', {bol: false, name: 'induk'})
                commit('isNext', false)
            } catch (error) {
                commit('isLoading', {bol: false, name: 'induk'})
                commit('isNext', true)
                console.log(error)
                if (error.response.status == 404) {
                    commit('dataNpwrd', [])
                    commit('existNpwrd', {bol: true, name: 'induk'})
                }
            }
        },
        async allNpwrd ({ commit }) {
            try {
                let response = await axios.get("/api/wajib-retribusi/npwrd")
                commit("inputDataNpwrd", response.data.data)
            } catch (error) {
                console.log(error)
            }
        }
    }
}