import axios from 'axios'
import router from '@/router'

export default {
    namespaced: true,
    state: {
        perangkat: [],
        unit: []
    },
    mutations: {
        dataPerangkat (state, data) {
            state.perangkat = data
        },
        dataUnit (state, data) {
            state.unit = data
        }
    },
    actions: {
       getPerangkat ({ commit }) {
            axios.get("/api/pemerintah-daerah/perangkat-pd/getoption").then(response => {
                commit('dataPerangkat', response.data.data)
            }).catch(error => {
                console.log(error)
                if (error.response.status === 401) {
                    localStorage.clear()
                    router.push({ name: 'Login' })
                }
            })
        },
        getUnit ({ commit }, id) {
            axios.get("/api/pemerintah-daerah/unit-pd/getoption/perangkat-pd-" +id).then(response => {
                commit('dataUnit', response.data.data)
            }).catch(error => {
                console.log(error)
                if (error.response.status === 401) {
                    localStorage.clear()
                    router.push({ name: 'Login' })
                }
            })
        }
    }
}
