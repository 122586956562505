import axios from 'axios'
import router from '@/router'

export default {
    namespaced: true,
    state: {
        dataSpt: [],
        dataSurat: [],
    },
    mutations: {
        inputDataSpt (state, data) {
            state.dataSpt = data
        },
        inputDataSurat (state, data) {
            state.dataSurat = data
        },
    },
    actions: {
        async sptStore ({ commit }) {
            try {
                let response = await axios.get("/api/transaksi/spt/getoption/belum-lunas")
                commit("inputDataSpt", response.data.data)
            } catch (error) {
                console.log(error)
                if (error.response.status === 401) {
                    localStorage.clear()
                    router.push({ name: 'Login' })
                }
            }
        },
        async suratStore ({ commit }, spt_id) {
            try {
                let response = await axios.get("/api/transaksi/surat/getoption/spt-" + spt_id)
                commit("inputDataSurat", response.data.data)
            } catch (error) {
                console.log(error)
                if (error.response.status === 401) {
                    localStorage.clear()
                    router.push({ name: 'Login' })
                }
            }
        },
    }
}