import axios from 'axios'
import router from '@/router/'

export default {
    namespaced: true,
    state: {
        jabatanOption: []
    },
    mutations: {
        jabatanData (state, data) {
            state.jabatanOption = data
        }
    },
    actions: {
        jabatanApi ({ commit }) {
            axios.get('/api/data-induk/kepegawaian/jabatan/getoption').then( response => {
                commit('jabatanData', response.data.data)
            }).catch(error => {
                console.log(error)
                if (error.response.status === 401) {
                    localStorage.clear()
                    router.push({ name: 'Login' })
                }
            })
        }
    }
}