// authentication
const Login = () => import(/* webpackChunkName: "login" */ '@/views/auth/Login')
const Logout = () => import(/* webpackChunkName: "logout" */ '@/views/auth/Logout')
const RegistrasiWajibRet = () => import(/* webpackChunkName: "registrasiwajibret" */ '@/views/auth/RegistrasiWajibRet')
const RegistrasiPegawai = () => import(/* webpackChunkName: "registrasiwajibret" */ '@/views/auth/RegistrasiPegawai')
const ResendEmail = () => import(/* webpackChunkName: "resendemail" */ '@/views/auth/ResendEmail')

// Dashboarsd
const Dashboard = () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard')

// Menu
// npwr
const NPWR = () => import(/* webpackChunkName: "npwr" */ '@/views/menu/npwr/Npwr')
const PendaftaranPerorangan = () => import(/* webpackChunkName: "pendaftaranperorangan" */ '@/components/npwr/PendaftaranIndividu')
const DaftarNpwrd = () => import(/* webpackChunkName: "daftarnpwrd" */ '@/components/npwr/StepProfil.vue')
const DaftarPimpinan = () => import(/* webpackChunkName: "daftarpimpinan" */ '@/components/npwr/StepPimpinan.vue')
const DaftarPenanggungJawab = () => import(/* webpackChunkName: "daftarpenanggungjawab" */ '@/components/npwr/StepPenanggungJawab.vue')


// sptr
const SPTR = () => import(/* webpackChunkName: "tambah sptr" */ '@/views/menu/sptr/SPTR')
const STEP1 = () => import(/* webpackChunkName: "step 1" */ '@/views/menu/sptr/STEP1')
const STEP2 = () => import(/* webpackChunkName: "step 2" */ '@/views/menu/sptr/STEP2')
const STEP3 = () => import(/* webpackChunkName: "step 3" */ '@/views/menu/sptr/STEP3')
const STEP4 = () => import(/* webpackChunkName: "step 4" */ '@/views/menu/sptr/STEP4')
const ListSPTR = () => import(/* webpackChunkName: "listsptr" */ '@/views/menu/sptr/ListSPTR.vue')

//==== file master ====
// Kode Rekening
const ListKodeRekening = () => import(/* webpackChunkName: "listkoderekening" */ '@/views/file_master/kode_rekening/ListKodeRekening.vue')

// Wilayah
const ListKodeWilayah = () => import(/* webpackChunkName: "listkodewilayah" */ '@/views/file_master/wilayah/ListKodeWilayah.vue')

//===== SKPD =====
const ListSKPD = () => import(/* webpackChunkName: "listskpd" */ '@/views/file_master/skpd/ListSKPD.vue')
const TambahSKPD = () => import(/* webpackChunkName: "tambahskpd" */ '@/views/file_master/skpd/TambahSKPD.vue')
//================

//===== SKPD/UPT =====

//====================

// pejabat
const ListPejabat = () => import(/* webpackChunkName: "listpejabat" */ '@/views/file_master/pejabat/ListPejabat.vue')
const TambahPejabat = () => import(/* webpackChunkName: "tambahpejabat" */ '@/views/file_master/pejabat/TambahPejabat.vue')
const EditPejabat = () => import(/* webpackChunkName: "editpejabat" */ '@/views/file_master/pejabat/EditPejabat.vue')


// ==================================================
// Data Induk
// --------------------------------------------------
// JDIH
const JenisHukum_List = () => import(/* webpackChunkName: "jenishukum_list" */ '@/views/data_induk/jdih/JenisHukum_List.vue')
const ProdukHukum_List = () => import(/* webpackChunkName: "produkhukum_list" */ '@/views/data_induk/jdih/ProdukHukum_List.vue')
// APBD
const Target_List = () => import(/* webpackChunkName: "target_list" */ '@/views/data_induk/apbd/Target_List.vue')



//=======================

// ssrd
const ListSSRD = () => import(/* webpackChunkName: "listssrd" */ '@/views/menu/ssrd/ListSSRD.vue')
// const BuatSSRD = () => import(/* webpackChunkName: "buatssrd" */ '@/views/menu/ssrd/BuatSSRD.vue')
const UpdateBuktiBayar = () => import(/* webpackChunkName: "updatebuktibayar" */ '@/views/menu/ssrd/UpdateBuktiBayar.vue')

// stsrd
const BuatSTSRD = () => import(/* webpackChunkName: "buatstsrd" */ '@/views/menu/stsrd/BuatSTSRD.vue')
const ListSTSRD = () => import(/* webpackChunkName: "liststsrd" */ '@/views/menu/stsrd/ListSTSRD.vue')

//===== Tarif ======
// retribusi per skpd
const ListRetribusiSkpd = () => import(/* webpackChunkName: "listretribusiskpd" */ '@/views/file_master/tarif/retribusi_skpd/ListRetribusiSkpd.vue')

// jenis retribusi
const ListJenisRetribusi = () => import(/* webpackChunkName: "listjenisretribusi" */ '@/views/file_master/tarif/jenis_retribusi/ListJenisRetribusi.vue')

// sub jenis retribusi
const ListSubJenis = () => import(/* webpackChunkName: "listsubjenisretribusi" */ '@/views/file_master/tarif/sub_jenis/ListSubJenis.vue')

// sub 2 jenis retribusi
const ListSubDuaJenis = () => import(/* webpackChunkName: "listsubduajenisretribusi" */ '@/views/file_master/tarif/sub_dua_jenis/ListSubDuaJenis.vue')

// retribusi
const ListTarifRetribusi = () => import(/* webpackChunkName: "listtarifretribusi" */ '@/views/file_master/tarif/retribusi/ListTarifRetribusi.vue')

// pencarian
const Pencarian = () => import(/* webpackChunkName: "pencarian" */ '@/views/file_master/tarif/pencarian/Pencarian.vue')
//==================

//=======================

//===== Pelaporan ======
// Transaksi
const LapSKRD = () => import(/* webpackChunkName: "lapskrd" */ '@/views/pelaporan/transaksi/spt/LapSKRD.vue')
// const LapSKRDFilter = () => import(/* webpackChunkName: "lapskrdfilter" */ '@/views/pelaporan/transaksi/spt/LapSKRDFilter.vue')
// const LapSKRDView = () => import(/* webpackChunkName: "lapskrdview" */ '@/views/pelaporan/transaksi/spt/LapSKRDView.vue')
// ---
const LapSSRD = () => import(/* webpackChunkName: "lapssrd" */ '@/views/pelaporan/transaksi/ssr/LapSSRD.vue')
// const LapSSRDFilter = () => import(/* webpackChunkName: "lapssrdfilter" */ '@/views/pelaporan/transaksi/ssr/LapSSRDFilter.vue')
const LaporanRealisasiItem = () => import(/* webpackChunkName: "laprealisasi" */ '@/views/pelaporan/transaksi/realisasi/LaporanRealisasiItem.vue')
const LaporanRealisasi = () => import(/* webpackChunkName: "laprealisasi" */ '@/views/pelaporan/transaksi/realisasi/LaporanRealisasi.vue')

// spj
const BukuRekapitulasi = () => import(/* webpackChunkName: "bukurekapitulasi" */ '@/views/pelaporan/spj/rekapitulasi/BukuRekapitulasi.vue')
const BukuKas = () => import(/* webpackChunkName: "bukukas" */ '@/views/pelaporan/spj/bku/BKU.vue')
const BukuPembantu = () => import(/* webpackChunkName: "bukupembantu" */ '@/views/pelaporan/spj/pembantu/BukuPembantu.vue')
const SPJPenerimaan = () => import(/* webpackChunkName: "spjpenerimaan" */ '@/views/pelaporan/spj/penerimaan/SPJPenerimaan.vue')
//======================

//===== Data ========
const TarifRetribusi = () => import(/* webpackChunkName: "tarifretribusi" */ '@/views/data/TarifRetribusi.vue')
//===================

//==== Management Users ======
const ListModul = () => import(/* webpackChunkName: "listmodul" */ '@/views/manajemen_users/modul_retribusi/ListModul.vue')
const TambahModul = () => import(/* webpackChunkName: "tambahmodul" */ '@/views/manajemen_users/modul_retribusi/TambahModul.vue')
//============================

//==== Level Users =====
const ListLevel = () => import(/* webpackChunkName: "listlevel" */ '@/views/manajemen_users/level/ListLevel.vue')
const TambahLevel = () => import(/* webpackChunkName: "tambahlevel" */ '@/views/manajemen_users/level/TambahLevel.vue')
//======================

//==== User ID ======
const ListUserId = () => import(/* webpackChunkName: "listuserid" */ '@/views/manajemen_users/user_id/ListUserId.vue')
const TambahUserId = () => import(/* webpackChunkName: "tambahuserid" */ '@/views/manajemen_users/user_id/TambahUserId.vue')
const EditUserId = () => import(/* webpackChunkName: "edituserid" */ '@/views/manajemen_users/user_id/EditUserId.vue')
//===================

//===== Pengguna Users ID =====
const ListPenggunaId = () => import(/* webpackChunkName: "listpenggunaid" */ '@/views/manajemen_users/pengguna_id/ListPenggunaId.vue')
const TambahPenggunaId = () => import(/* webpackChunkName: "tambahpenggunaid" */ '@/views/manajemen_users/pengguna_id/TambahPenggunaId.vue')
//=============================

//===== Ganti Password =====
const GantiPassword = () => import(/* webpackChunkName: "gantipassword" */ '@/views/manajemen_users/ganti_password/GantiPassword.vue')
//==========================

//===== History User ====
const HistoryUser = () => import(/* webpackChunkName: "historyuser" */ '@/views/manajemen_users/history_user/HistoryUser.vue')
//=======================

//==== page 404 =====
const Page404 = () => import(/* webpackChunkName: "page404" */ '@/views/pages/Page404.vue')
//===================

//===== Kepegawaian =======
const ListJabatan = () => import(/* webpackChunkName: "jabatan" */ '@/views/kepegawaian/jabatan/ListJabatan.vue')
const ListPangkat = () => import(/* webpackChunkName: "pangkat" */ '@/views/kepegawaian/pangkat/ListPangkat.vue')
const ListPegawai = () => import(/* webpackChunkName: "pegawai" */ '@/views/kepegawaian/pegawai/ListPegawai.vue')
const DetailPegawai = () => import(/* webpackChunkName: "detailpegawai" */ '@/views/kepegawaian/pegawai/DetailPegawai.vue')

//====== Profil Pengguna =========
const ProfilPengguna = () => import(/* webpackChunkName: "profilpengguna" */ '@/views/user/ProfilPengguna.vue')
const ListPengguna = () => import(/* webpackChunkName: "listpengguna" */ '@/views/user/ListPengguna.vue')


//==== Pemerintah Daerah =====
const Klasifikasi = () => import(/* webpackChunkName: "listklasifikasi" */ '@/views/pemerintah_daerah/klasifikasi/ListKlasifikasi.vue')
const Urusan = () => import(/* webpackChunkName: "listurusan" */ '@/views/pemerintah_daerah/urusan/ListUrusan.vue')
const Perangkat = () => import(/* webpackChunkName: "listperangkat" */ '@/views/pemerintah_daerah/perangkat/ListPerangkat.vue')
const Unit = () => import(/* webpackChunkName: "listunit" */ '@/views/pemerintah_daerah/unit/ListUnit.vue')

//=== Kode Bayar ======
const ListKodeBayar = () => import(/* webpackChunkName: "listkodebayar" */ '@/views/menu/kode_bayar/ListKodeBayar.vue')
const BuatKodeBayar = () => import(/* webpackChunkName: "Buatkodebayar" */ '@/views/menu/kode_bayar/BuatKodeBayar.vue')

//=== Wilayah Administratif =====
const ListProvinsi = () => import(/* webpackChunkName: "listprovinsi" */ '@/views/wilayah/provinsi/ListProvinsi.vue')
const ListKabkota = () => import(/* webpackChunkName: "listkabkota" */ '@/views/wilayah/kabkota/ListKabkota.vue')
const ListKecamatan = () => import(/* webpackChunkName: "listkecamatan" */ '@/views/wilayah/kecamatan/ListKecamatan.vue')
const ListKelurahan = () => import(/* webpackChunkName: "listkelurahan" */ '@/views/wilayah/kelurahan/ListKelurahan.vue')

//==== Hak Akses =====
const Role = () => import(/* webpackChunkName: "role" */ '@/views/hak_akses/Role.vue')
const Permission = () => import(/* webpackChunkName: "permission" */ '@/views/hak_akses/Permission.vue')

//==== Retribusi admin =====
const ListRetJenis1 = () => import(/* webpackChunkName: "listretjenis1" */ '@/views/retribusi/retjenis1/ListRetJenis1.vue')
const ListRetJenis2 = () => import(/* webpackChunkName: "listretjenis2" */ '@/views/retribusi/retjenis2/ListRetJenis2.vue')
const ListRetJenis3 = () => import(/* webpackChunkName: "listretjenis3" */ '@/views/retribusi/retjenis3/ListRetJenis3.vue')
const ListTarifRet = () => import(/* webpackChunkName: "listtarifret" */ '@/views/retribusi/tarif_retribusi/ListTarifRet.vue')

//==== Profil Wajib Retribusi ====
const DetailWajibRetribusi = () => import(/* webpackChunkName: "detailwajibretribusi" */ '@/views/wajib_retribusi/DetailWajibRetribusi.vue')

//==== Status Aplikasi ========
const ListStatusAplikasi = () => import(/* webpackChunkName: "liststatusaplikasi" */ '@/views/status_aplikasi/ListStatusAplikasi.vue')

//===== Tiket Masuk =======
const Container = () => import(/* webpackChunkName: "tiketmasuk" */ '@/views/menu/tiket_masuk/Container.vue')
const StepOne = () => import(/* webpackChunkName: "tiketmasuk" */ '@/views/menu/tiket_masuk/StepOne.vue')
const StepTwo = () => import(/* webpackChunkName: "tiketmasuk" */ '@/views/menu/tiket_masuk/StepTwo.vue')
const StepThree = () => import(/* webpackChunkName: "tiketmasuk" */ '@/views/menu/tiket_masuk/StepThree.vue')



const routes = [
	{ path: '*', redirect: '/dashboard' },

    // E-Retribusi
    // AUTH
    // ==================================================
    {
        path: '/auth',
        redirect: '/auth/login',
        name: 'Auth',
        component: {
        render(c) {
            return c('router-view')
        }
        },
        children: [{
            path: 'login',
            name: 'Login',
            component: Login,
            meta: {
                title: 'Login'
            },
        },
        {
            path: 'logout',
            name: 'Logout',
            component: Logout,
            meta: {
                title: 'Logout'
            },
        },
        {
            path: 'registrasi',
            component: {
                render(c) {
                    return c('router-view')
                }
            },
            children: [
                {
                    path: 'wajib-retribusi',
                    name: 'RegistrasiWajibRet',
                    component: RegistrasiWajibRet,
                    meta: {
                        title: 'Pendaftaran Akun Wajib Retribusi'
                    },
                },
                {
                    path: 'pegawai',
                    name: 'RegistrasiPegawai',
                    component: RegistrasiPegawai,
                    meta: {
                        title: 'Pendaftaran Akun Pegawai'
                    },
                },
                {
                    path: 'kirim-ulang',
                    name: 'ResendEmail',
                    component: ResendEmail,
                    meta: {
                        title: 'Kirim Ulang Email Verifikasi'
                    },
                },
            ]
        }
        ]
    },
    {
        path: '/dashboard',
        component: Dashboard,
        meta: {
            requiresAuth: true,
            title: 'Dashboard',
        }
    },

    // Menu
    /**
     * submenu di bawah menu
     * */
    {
        path: '/pendaftaran',
        redirect: "/pendaftaran/profil-npwrd",
        component: PendaftaranPerorangan,
        children: [
            {
                path: 'profil-npwrd',
                name: 'DaftarNpwrd',
                component: DaftarNpwrd,
                meta: {
                    requiresAuth: true,
                    title: 'List NPWRD'
                }
            },
            {
                path: 'profil-npwrd/edit/:id',
                name: 'EditNpwrd',
                component: DaftarNpwrd,
                meta: {
                    requiresAuth: true,
                    title: 'List NPWRD'
                }
            },
            {
                path: 'pimpinan',
                name: 'DaftarPimpinan',
                component: DaftarPimpinan,
                meta: {
                    requiresAuth: true,
                    title: 'Pimpinan'
                }
            },
            {
                path: 'pimpinan/edit/:id',
                name: 'EditPimpinan',
                component: DaftarPimpinan,
                meta: {
                    requiresAuth: true,
                    title: 'Pimpinan'
                }
            },
            {
                path: 'penanggung-jawab',
                name: 'DaftarPenanggungJawab',
                component: DaftarPenanggungJawab,
                meta: {
                    requiresAuth: true,
                    title: 'Penanggung Jawab'
                }
            },
            {
                path: 'penanggung-jawab/edit/:id',
                name: 'EditPenanggungJawab',
                component: DaftarPenanggungJawab,
                meta: {
                    requiresAuth: true,
                    title: 'Pimpinan'
                }
            },
        ]
    },
    {
        path: '/menu',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            // npwrd
            {
                path: 'npwrd',
                redirect: '/menu/npwrd/list',
                component: {
                    render(c) {
                        return c('router-view')
                    }
                },
                children: [
                    {
                        path: 'list',
                        name: 'ListNPWRD',
                        component: NPWR,
                        meta: {
                            requiresAuth: true,
                            title: 'List NPWRD'
                        }
                    },
                ]
            },
            // end of npwrd

            // sptr
            {
                path: 'sptrd',
                component: {
                    render(c) {
                        return c('router-view')
                    }
                },
                children: [
                    // tiket masuk
                    {
                        path: 'tiket',
                        redirect: '/menu/sptrd/tiket/step-1',
                        component: Container,
                        meta: {
                            requiresAuth: true,
                            title: 'Buat Tiket Masuk'
                        },
                        children: [
                            {
                                path: 'step-1',
                                name: 'StepOne',
                                component: StepOne,
                                meta: {
                                    requiresAuth: true,
                                    title: 'Step 1. Wajib Retribusi'
                                }
                            },
                            {
                                path: 'step-1/next/:id',
                                name: 'NextStepOne',
                                component: StepOne,
                                meta: {
                                    requiresAuth: true,
                                    title: 'Step 1. Wajib Retribusi'
                                }
                            },
                            {
                                path: 'step-2',
                                name: 'StepTwo',
                                component: StepTwo,
                                meta: {
                                    requiresAuth: true,
                                    title: 'Step 2. Detail Tiket Masuk'
                                }
                            },
                            {
                                path: 'step-2/next/:id',
                                name: 'NextStepTwo',
                                component: StepTwo,
                                meta: {
                                    requiresAuth: true,
                                    title: 'Step 2. Detail Tiket Masuk'
                                }
                            },
                            {
                                path: 'step-3',
                                name: 'StepThree',
                                component: StepThree,
                                meta: {
                                    requiresAuth: true,
                                    title: 'Step 3. Selesai'
                                }
                            },
                        ]
                    },
                    // list sptrd
                    {
                        path: 'list',
                        name: 'ListSPTR',
                        component: ListSPTR,
                        meta: {
                            requiresAuth: true,
                            title: 'List SPTR Daerah'
                        }
                    },
                    // step buat sptrd
                    {
                        path: 'pendaftaran',
                        redirect: '/menu/sptrd/pendaftaran/step-1',
                        component: SPTR,
                        children: [
                            {
                                path: 'step-1',
                                name: 'Step1',
                                component: STEP1,
                                meta: {
                                    requiresAuth: true,
                                    title: 'Pendaftaran - STEP 1'
                                },
                            },
                            {
                                path: 'step-1/next/:id',
                                name: 'NextStep1',
                                component: STEP1,
                                meta: {
                                    requiresAuth: true,
                                    title: 'Pendaftaran - STEP 1'
                                },
                            },
                            {
                                path: 'step-2',
                                name: 'Step2',
                                component: STEP2,
                                meta: {
                                    requiresAuth: true,
                                    title: 'Pendaftaran - STEP 2'
                                }
                            },
                            {
                                path: 'step-2/next/:id',
                                name: 'NextStep2',
                                component: STEP2,
                                meta: {
                                    requiresAuth: true,
                                    title: 'Pendaftaran - STEP 2'
                                },
                            },
                            {
                                path: 'step-3',
                                name: 'Step3',
                                component: STEP3,
                                meta: {
                                    requiresAuth: true,
                                    title: 'Pendaftaran - STEP 3'
                                }
                            },
                            {
                                path: 'step-3/next/:id',
                                name: 'NextStep3',
                                component: STEP3,
                                meta: {
                                    requiresAuth: true,
                                    title: 'Pendaftaran - STEP 3'
                                },
                            },
                            {
                                path: 'step-4',
                                name: 'Step4',
                                component: STEP4,
                                meta: {
                                    requiresAuth: true,
                                    title: 'Pendaftaran - STEP 4'
                                }
                            }
                            // end of buat
                        ]
                    },
                ]
            },
            // end of sptr

            // kode bayar

            {
                path: 'kode-bayar',
                component: {
                    render(c) {
                        return c('router-view')
                    }
                },
                children: [
                    // list
                    {
                        path: 'list',
                        name: 'ListKodeBayar',
                        component: ListKodeBayar,
                        meta: {
                            requiresAuth: true,
                            title: 'List Kode Bayar'
                        }
                    },
                    // buat
                    {
                        path: 'buat',
                        name: 'BuatKodeBayar',
                        component: BuatKodeBayar,
                        meta: {
                            requiresAuth: true,
                            title: 'Buat Kode Bayar'
                        }
                    },
                    // edit
                    {
                        path: 'edit/:id',
                        name: 'EditKodeBayar',
                        component: BuatKodeBayar,
                        meta: {
                            requiresAuth: true,
                            title: 'Edit Kode Bayar'
                        }
                    },
                ]
            },

            // ssrd
            {
                path: 'ssrd',
                redirect: '/menu/ssrd/list',
                component: {
                    render(c) {
                        return c('router-view')
                    }
                },
                children: [
                    // list
                    {
                        path: 'list',
                        name: 'ListSSRD',
                        component: ListSSRD,
                        meta: {
                            requiresAuth: true,
                            title: 'List SSR Daerah'
                        }
                    },
                    // end of list

                    // buat ssrd
                    {
                        path: 'buat',
                        name: 'BuatSSRD',
                        component: UpdateBuktiBayar,
                        meta: {
                            requiresAuth: true,
                            title: 'Buat SSR Daerah'
                        }
                    },
                    // end of buat

                    // edit ssrd
                    {
                        path: 'edit/:id',
                        name: 'EditSSRD',
                        component: UpdateBuktiBayar,
                        meta: {
                            requiresAuth: true,
                            title: 'Edit SSR Daerah'
                        }
                    },
                    // end of buat

                    // // update bukti bayar ssrd
                    // {
                    //     path: 'pembayaran-cash',
                    //     name: 'UpdateBuktiBayar',
                    //     component: UpdateBuktiBayar,
                    //     meta: {
                    //         requiresAuth: true,
                    //         title: 'Update Pembayaran'
                    //     }
                    // },
                    // // end of update
                ]
            },
            // end of ssrd

            // stsrd
            {
                path: 'stsrd',
                redirect: '/menu/stsrd/list',
                component: {
                    render(c) {
                        return c('router-view')
                    }
                },
                children: [
                    // list
                    {
                        path: 'list',
                        name: 'ListSTSRD',
                        component: ListSTSRD,
                        meta: {
                            requiresAuth: true,
                            title: 'List STSR Daerah'
                        }
                    },
                    // end of list

                    {
                        path: 'buat',
                        name: 'BuatSTSRD',
                        component: BuatSTSRD,
                        meta: {
                            requiresAuth: true,
                            title: 'Buat STSR Daerah'
                        }
                    },

                    {
                        path: 'edit/:id',
                        name: 'EditSTSRD',
                        component: BuatSTSRD,
                        meta: {
                            requiresAuth: true,
                            title: 'Edit STSR Daerah'
                        }
                    },
                ]
            },
            // end of stsrd
        ]
    },
    // end of menu

    /**
     * ==================================================
     * Data Induk
     * --------------------------------------------------
     */
    {
        path: '/data-induk',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: 'jdih',
                component: {
                    render(c) {
                        return c('router-view')
                    }
                },
                children: [
                    {
                        path: 'jenis-hukum',
                        // redirect: '/data-induk/jdih/jenis-hukum',
                        redirect: '/data-induk/jdih/jenis-hukum/list',
                        component: {
                            render(c) {
                                return c('router-view')
                            }
                        },
                        children: [
                            {
                                path: '',
                                // path: 'list',
                                name: 'JenisHukum_List',
                                component: JenisHukum_List,
                                meta: {
                                    requiresAuth: true,
                                    title: 'List Jenis Hukum'
                                }
                            }
                        ]
                    },
                    {
                        path: 'produk-hukum',
                        redirect: '/data-induk/jdih/produk-hukum',
                        component: {
                            render(c) {
                                return c('router-view')
                            }
                        },
                        children: [
                            {
                                path: '',
                                name: 'ProdukHukum_List',
                                component: ProdukHukum_List,
                                meta: {
                                    requiresAuth: true,
                                    title: 'List Jenis Hukum'
                                }
                            },
                        ]
                    }
                ]
            },
            {
                path: 'apbd',
                component: {
                    render(c) {
                        return c('router-view')
                    }
                },
                children: [
                    {
                        path: 'target',
                        redirect: '/data-induk/apbd/target',
                        component: {
                            render(c) {
                                return c('router-view')
                            }
                        },
                        children: [
                            {
                                path: '',
                                name: 'Target_List',
                                component: Target_List,
                                meta: {
                                    requiresAuth: true,
                                    title: 'List Target'
                                }
                            },
                        ]
                    }
                ]
            },
        ],
    },
    // end of target


    // File Master
    /**
     * submenu yang termasuk ke dalam menu file master
     */
    {
        path: '/file-master',
        name: 'FileMaster',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            // kode rekening
            {
                path: 'kode-rekening',
                redirect: '/file-master/kode-rekening/list',
                component: {
                    render(c) {
                        return c('router-view')
                    }
                },
                children: [
                    {
                        path: 'list',
                        name: 'ListKodeRekening',
                        component: ListKodeRekening,
                        meta: {
                            requiresAuth: true,
                            title: 'Kode Rekening'
                        }
                    }
                ]
            },
            // end of kode rekening

            // wilayah
            {
                path: 'wilayah',
                redirect: '/file-master/wilayah/list',
                component: {
                    render(c) {
                        return c('router-view')
                    }
                },
                children: [
                    {
                        path: 'list',
                        name: 'ListKodeWilayah',
                        component: ListKodeWilayah,
                        meta: {
                            requiresAuth: true,
                            title: 'List Wilayah'
                        }
                    }
                ]
            },
            // end of wilayah

            // skpd
            {
                path: 'perangkat',
                redirect: '/file-master/perangkat/list',
                component: {
                    render(c) {
                        return c('router-view')
                    }
                },
                children: [
                    {
                        path: 'list',
                        name: 'ListSKPD',
                        component: ListSKPD,
                        meta: {
                            requiresAuth: true,
                            title: 'List Perangkat'
                        }
                    },
                    {
                        path: 'tambah',
                        name: 'TambahSKPD',
                        component: TambahSKPD,
                        meta: {
                            requiresAuth: true,
                            title: 'Tambah Perangkat'
                        }
                    }
                ]
            },
            // end of skpd

            // pegawai
            {
                path: 'pegawai',
                redirect: '/file-master/pegawai/list',
                component: {
                    render(c) {
                        return c('router-view')
                    }
                },
                children: [
                    {
                        path: 'list',
                        name: 'ListPejabat',
                        component: ListPejabat,
                        meta: {
                            requiresAuth: true,
                            title: 'List Pegawai'
                        }
                    },
                    {
                        path: 'tambah',
                        name: 'TambahPejabat',
                        component: TambahPejabat,
                        meta: {
                            requiresAuth: true,
                            title: 'Tambah Pegawai'
                        }
                    },
                    {
                        path: 'edit/:id',
                        name: 'EditPejabat',
                        component: EditPejabat,
                        meta: {
                            requiresAuth: true,
                            title: 'Edit Pegawai'
                        }
                    }
                ]
            },
            // end of pejabat

            // tarif
            {
                path: 'tarif',
                component: {
                    render(c) {
                        return c('router-view')
                    }
                },
                children: [
                    // retribusi per skpd
                    {
                        path: 'retribusi-skpd',
                        component: {
                            render(c) {
                                return c('router-view')
                            }
                        },
                        children: [
                            {
                                path: 'list',
                                name: 'ListRetribusiSKPD',
                                component: ListRetribusiSkpd,
                                meta: {
                                    requiresAuth: true,
                                    title: 'List Retribusi Per SKPD'
                                }
                            }
                        ]
                    },
                    // end of retri skpd

                    // jenis retribusi
                    {
                        path: 'jenis-retribusi',
                        component: {
                            render(c) {
                                return c('router-view')
                            }
                        },
                        children: [
                            {
                                path: 'list',
                                name: 'ListJenisRetribusi',
                                component: ListJenisRetribusi,
                                meta: {
                                    requiresAuth: true,
                                    title: 'List Jenis Retriibusi'
                                }
                            }
                        ]
                    },
                    // end of jenis

                    // sub jenis retribusi
                    {
                        path: 'sub-jenis',
                        component: {
                            render(c) {
                                return c('router-view')
                            }
                        },
                        children: [
                            {
                                path: 'list',
                                name: 'ListSubJenis',
                                component: ListSubJenis,
                                meta: {
                                    requiresAuth: true,
                                    title: 'List Sub Jenis Retribusi'
                                }
                            }
                        ]
                    },
                    // end of sub jenis

                    // sub 2 jenis retribusi
                    {
                        path: 'sub2-jenis',
                        component: {
                            render(c) {
                                return c('router-view')
                            }
                        },
                        children: [
                            {
                                path: 'list',
                                name: 'ListSubDuaJenis',
                                component: ListSubDuaJenis,
                                meta: {
                                    requiresAuth: true,
                                    title: 'List Sub 2 Jenis Retribusi'
                                }
                            }
                        ]
                    },
                    // end of sub 2 jenis

                    // tarif retribusi
                    {
                        path: 'retribusi',
                        component: {
                            render(c) {
                                return c('router-view')
                            }
                        },
                        children: [
                            {
                                path: 'list',
                                name: 'ListTarifRetribusi',
                                component: ListTarifRetribusi,
                                meta: {
                                    requiresAuth: true,
                                    title: 'List Tarif Retribusi'
                                }
                            }
                        ]
                    },
                    // end of tarif retribusi

                    // pencarian
                    {
                        path: 'pencarian',
                        component: {
                            render(c) {
                                return c('router-view')
                            }
                        },
                        children: [
                            {
                                path: '',
                                name: 'Pencarian',
                                component: Pencarian,
                                meta: {
                                    requiresAuth: true,
                                    title: 'Pencarian Tarif'
                                }
                            }
                        ]
                    },
                    // end of pencarian
                ]
            },
            // end of tarif

        ]
    },
    // end of file master

    // pelaporan
    {
        path: '/pelaporan',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: 'transaksi',
                component: {
                    render(c) {
                        return c('router-view')
                    }
                },
                children: [
                    // {
                    //     path: 'spt',
                    //     component: {
                    //         render(c) {
                    //             return c('router-view')
                    //         }
                    //     },
                    //     children: [
                    //         {
                    //             path: 'skrd-filter',
                    //             name: 'LapSKRDFilter',
                    //             component: LapSKRDFilter,
                    //             meta: {
                    //                 requiresAuth: true,
                    //                 title: 'Form Filter SKRD'
                    //             }
                    //         },
                    //         {
                    //             path: 'skrd-view',
                    //             name: 'LapSKRDView',
                    //             component: LapSKRDView,
                    //             meta: {
                    //                 requiresAuth: true,
                    //                 title: 'Laporan SKRD'
                    //             }
                    //         },
                    //     ]
                    // },
                    {
                        path: 'skrd',
                        name: 'LapSKRD',
                        component: LapSKRD,
                        meta: {
                            requiresAuth: true,
                            title: 'Laporan SKRD'
                        }
                    },
                    // {
                    //     path: 'ssrd-filter',
                    //     name: 'LapSSRDFilter',
                    //     component: LapSSRDFilter,
                    //     meta: {
                    //         requiresAuth: true,
                    //         title: 'Form Filter SSRD'
                    //     }
                    // },
                    // {
                    //     path: 'ssrd-view',
                    //     name: 'LapSSRDView',
                    //     component: LapSSRDView,
                    //     meta: {
                    //         requiresAuth: true,
                    //         title: 'Laporan SSRD'
                    //     }
                    // },
                    {
                        path: 'ssrd',
                        name: 'LapSSRD',
                        component: LapSSRD,
                        meta: {
                            requiresAuth: true,
                            title: 'Laporan SSRD'
                        }
                    },
                    {
                        path: 'realisasi-penerimaan',
                        name: 'LaporanRealisasi',
                        component: LaporanRealisasi,
                        meta: {
                            requiresAuth: true,
                            title: 'Laporan Realisasi Penerimaan'
                        }
                    },
                    {
                        path: 'realisasi-penerimaan-item',
                        name: 'LaporanRealisasiItem',
                        component: LaporanRealisasiItem,
                        meta: {
                            requiresAuth: true,
                            title: 'Laporan Realisasi Penerimaan Per Item'
                        }
                    },
                ]
            },

            // spj
            {
                path: 'spj',
                component: {
                    render(c) {
                        return c('router-view')
                    }
                },
                children: [
                    // buku rekapitulasi
                    {
                        path: 'rekapitulasi',
                        name: 'BukuRekapitulasi',
                        component: BukuRekapitulasi,
                        meta: {
                            requiresAuth: true,
                            title: 'Buku Rekapitulasi Penerimaan'
                        }
                    },

                    // Buku kas umum
                    {
                        path: 'kas-umum',
                        name: 'BukuKas',
                        component: BukuKas,
                        meta: {
                            requiresAuth: true,
                            title: 'Buku Kas Umum Penerimaan'
                        }
                    },

                    // Buku Pembantu
                    {
                        path: 'pembantu',
                        name: 'BukuPembantu',
                        component: BukuPembantu,
                        meta: {
                            requiresAuth: true,
                            title: 'Buku Pembantu Perincian Objek Penerimaan'
                        }
                    },

                    // spj penerimaan
                    {
                        path: 'penerimaan',
                        name: 'SPJPenerimaan',
                        component: SPJPenerimaan,
                        meta: {
                            requiresAuth: true,
                            title: 'SPJ Penerimaan'
                        }
                    }
                ]
            }
        ]
    },
    // end of pelaporan

    /**
     * PUSTAKA
     * --------------------------------------------------
     * group route: pustaka/...
     */
    {
        path: '/pustaka',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {

                path: 'tarif-retribusi',
                name: 'TarifRetribusi',
                component: TarifRetribusi,
                meta: {
                    requiresAuth: true,
                    title: 'Tarif Retribusi'
                }
            }
        ]
    },

    // management users
    {
        path: '/users',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            // modul e-retribusi
            {
                path: 'modul',
                redirect: '/users/modul/list',
                component: {
                    render(c) {
                        return c('router-view')
                    }
                },
                children: [
                    {
                        path: 'list',
                        name: 'ListModul',
                        component: ListModul,
                        meta: {
                            requiresAuth: true,
                            title: 'List Modul E-retribusi'
                        }
                    },
                    {
                        path: 'tambah',
                        name: 'TambahModul',
                        component: TambahModul,
                        meta: {
                            requiresAuth: true,
                            title: 'Tambah Modul E-retribusi'
                        }
                    },
                ]
            },
            // end of modul

            // level users
            {
                path: 'level',
                redirect: '/users/level/list',
                component: {
                    render(c) {
                        return c('router-view')
                    }
                },
                children: [
                    {
                        path: 'list',
                        name: 'ListLevel',
                        component: ListLevel,
                        meta: {
                            requiresAuth: true,
                            title: 'List Level Users'
                        }
                    },
                    {
                        path: 'tambah',
                        name: 'TambahLevel',
                        component: TambahLevel,
                        meta: {
                            requiresAuth: true,
                            title: 'Tambah Level Users'
                        }
                    },
                ]
            },
            // end of level users

            // user id
            {
                path: 'users',
                redirect: '/users/users/list',
                component: {
                    render(c) {
                        return c('router-view')
                    }
                },
                children: [
                    {
                        path: 'list',
                        name: 'ListUserId',
                        component: ListUserId,
                        meta: {
                            requiresAuth: true,
                            title: 'List Users ID'
                        }
                    },
                    {
                        path: 'tambah',
                        name: 'TambahUserId',
                        component: TambahUserId,
                        meta: {
                            requiresAuth: true,
                            title: 'Tambah UsersID'
                        }
                    },
                    {
                        path: 'edit/:id',
                        name: 'EditUserId',
                        component: EditUserId,
                        meta: {
                            requiresAuth: true,
                            title: 'Edit UsersID'
                        }
                    },
                ]
            },
            // end of user id

            // pengguna users id
            {
                path: 'users-detail',
                redirect: '/users/users-detail/list',
                component: {
                    render(c) {
                        return c('router-view')
                    }
                },
                children: [
                    {
                        path: 'list',
                        name: 'ListPenggunaId',
                        component: ListPenggunaId,
                        meta: {
                            requiresAuth: true,
                            title: 'List Pengguna Users ID'
                        }
                    },
                    {
                        path: 'tambah',
                        name: 'TambahPenggunaId',
                        component: TambahPenggunaId,
                        meta: {
                            requiresAuth: true,
                            title: 'Tambah Pengguna Users ID'
                        }
                    },
                ]
            },
            // end of pengguna users id

            // ganti password
            {
                path: 'password',
                name: 'GantiPassword',
                component: GantiPassword,
                meta: {
                    requiresAuth: true,
                    title: 'Ganti Password'
                }
            },
            // end of ganti password

            // history user
            {
                path: 'user-history',
                name: 'HistoryUser',
                component: HistoryUser,
                meta: {
                    requiresAuth: true,
                    title: 'History User'
                }
            },
            // end of history user
        ]
    },
    // end of management users

    {
        path: '/page-not-found',
        name: 'Page404',
        component: Page404,
        meta: {
            requiresAuth: true,
            title: 'Halaman Tidak Ditemukan'
        }
    },

    // kepegawian
    {
        path: '/kepegawaian',
        component: {
            render(c) {
                return c("router-view");
            },
        },
        meta: {
            requiresAuth: true,
            title: 'Kepegawaian'
        },
        children: [
            {
                path: 'jabatan',
                name: 'ListJabatan',
                component: ListJabatan,
                meta: {
                    requiresAuth: true,
                    title: 'List Jabatan'
                }
            },
            {
                path: 'pangkat',
                name: 'ListPangkat',
                component: ListPangkat,
                meta: {
                    requiresAuth: true,
                    title: 'List Jabatan'
                }
            },
            {
                path: 'pegawai',
                name: 'ListPegawai',
                component: ListPegawai,
                meta: {
                    requiresAuth: true,
                    title: 'List Pegawai'
                }
            },
            {
                path: 'pegawai/:id',
                name: 'DetailPegawai',
                component: DetailPegawai,
                meta: {
                    requiresAuth: true,
                    title: 'Detail Pegawai'
                }
            },
        ]
    },

    // user
    {
        path: '/pengaturan',
        component: {
            render(c) {
                return c("router-view");
            },
        },
        meta: {
            requiresAuth: true,
            title: 'Pengaturan'
        },
        children: [
            {
                path: 'profil',
                name: 'ProfilPengguna',
                component: ProfilPengguna,
                meta: {
                    requiresAuth: true,
                    title: 'Profil Pengguna'
                }
            },
            {
                path: 'pengguna',
                name: 'ListPengguna',
                component: ListPengguna,
                meta: {
                    requiresAuth: true,
                    title: 'List Pengguna'
                }
            },
            {
                path: 'hak-akses',
                component: {
                    render(c) {
                        return c("router-view");
                    },
                },
                meta: {
                    requiresAuth: true,
                    title: 'List Pengguna'
                },
                children: [
                    {
                        path: 'role',
                        name: 'Role',
                        component: Role,
                        meta: {
                            requiresAuth: true,
                            title: 'Role'
                        }
                    },
                    {
                        path: 'permission',
                        name: 'Permission',
                        component: Permission,
                        meta: {
                            requiresAuth: true,
                            title: 'Permission'
                        }
                    },
                ]
            },
            {
                path: 'aplikasi',
                component: {
                    render(c) {
                        return c("router-view");
                    },
                },
                meta: {
                    requiresAuth: true,
                    title: 'List Pengguna'
                },
                children: [
                    {
                        path: 'status-aplikasi',
                        name: 'ListStatusAplikasi',
                        component: ListStatusAplikasi,
                        meta: {
                            requiresAuth: true,
                            title: 'Status Aplikasi'
                        }
                    },
                ]
            }
        ]
    },

    // pemerintah daerah
    {
        path: '/pemerintah-daerah',
        component: {
            render(c) {
                return c("router-view");
            },
        },
        meta: {
            requiresAuth: true,
            title: 'Kepegawaian'
        },
        children: [
            {
                path: 'klasifikasi',
                name: 'Klasifikasi',
                component: Klasifikasi,
                meta: {
                    requiresAuth: true,
                    title: 'Klasifikasi Pemerintah Daerah'
                }
            },
            {
                path: 'urusan',
                name: 'Urusan',
                component: Urusan,
                meta: {
                    requiresAuth: true,
                    title: 'Urusan Pemerintah Daerah'
                }
            },
            {
                path: 'perangkat',
                name: 'Perangkat',
                component: Perangkat,
                meta: {
                    requiresAuth: true,
                    title: 'Perangkat Pemerintah Daerah'
                }
            },
            {
                path: 'unit',
                name: 'Unit',
                component: Unit,
                meta: {
                    requiresAuth: true,
                    title: 'Unit Pemerintah Daerah'
                }
            },
        ]
    },

    // wilayah
    {
        path: '/wilayah',
        component: {
            render(c) {
                return c("router-view");
            },
        },
        meta: {
            requiresAuth: true,
            title: 'Wilayah Administratif'
        },
        children: [
            {
                path: 'provinsi',
                name: 'ListProvinsi',
                component: ListProvinsi,
                meta: {
                    requiresAuth: true,
                    title: 'List Provinsi'
                }
            },
            {
                path: 'kabkota',
                name: 'ListKabkota',
                component: ListKabkota,
                meta: {
                    requiresAuth: true,
                    title: 'List Kab./Kota'
                }
            },
            {
                path: 'kecamatan',
                name: 'ListKecamatan',
                component: ListKecamatan,
                meta: {
                    requiresAuth: true,
                    title: 'List Kecamatan'
                }
            },
            {
                path: 'kelurahan',
                name: 'ListKelurahan',
                component: ListKelurahan,
                meta: {
                    requiresAuth: true,
                    title: 'List Kelurahan'
                }
            },
        ]
    },

    // retribusi
    {
        path: '/retribusi',
        component: {
            render(c) {
                return c("router-view");
            },
        },
        meta: {
            requiresAuth: true,
            title: 'Retribusi'
        },
        children: [
            {
                path: 'ret-jenis-1',
                name: 'ListRetJenis1',
                component: ListRetJenis1,
                meta: {
                    requiresAuth: true,
                    title: 'List Jenis Retribusi 1'
                }
            },
            {
                path: 'ret-jenis-2',
                name: 'ListRetJenis2',
                component: ListRetJenis2,
                meta: {
                    requiresAuth: true,
                    title: 'List Jenis Retribusi 2'
                }
            },
            {
                path: 'ret-jenis-3',
                name: 'ListRetJenis3',
                component: ListRetJenis3,
                meta: {
                    requiresAuth: true,
                    title: 'List Jenis Retribusi 3'
                }
            },
            {
                path: 'tarif',
                name: 'ListTarifRet',
                component: ListTarifRet,
                meta: {
                    requiresAuth: true,
                    title: 'List Tarif Retribusi'
                }
            },
        ]
    },

    // profil wajib retribusi
    {
        path: '/profil',
        component: {
            render(c) {
                return c("router-view");
            },
        },
        children: [
            {
                path: 'wajib-retribusi',
                name: 'DetailWajibRetribusi',
                component: DetailWajibRetribusi,
                meta: {
                    requiresAuth: true,
                    title: 'Profil Wajib Retribusi'
                }
            },
        ]
    }


]

export default routes;
