var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.pageOptions.pageEmpty)?_c('div',{staticClass:"fade page-sidebar-fixed page-header-fixed show page-container",class:{ 
	'page-sidebar-minified': _vm.pageOptions.pageSidebarMinified, 
	'page-content-full-height': _vm.pageOptions.pageContentFullHeight, 
	'page-without-sidebar': _vm.pageOptions.pageWithoutSidebar, 
	'page-with-right-sidebar': _vm.pageOptions.pageWithRightSidebar, 
	'page-with-two-sidebar': _vm.pageOptions.pageWithTwoSidebar,
	'page-with-wide-sidebar': _vm.pageOptions.pageWithWideSidebar,
	'page-with-light-sidebar': _vm.pageOptions.pageWithLightSidebar,
	'page-with-top-menu': _vm.pageOptions.pageWithTopMenu,
	'page-sidebar-toggled': _vm.pageOptions.pageMobileSidebarToggled,
	'page-right-sidebar-toggled': _vm.pageOptions.pageMobileRightSidebarToggled || _vm.pageOptions.pageRightSidebarToggled,
	'page-right-sidebar-collapsed': _vm.pageOptions.pageRightSidebarCollapsed,
	'has-scroll': _vm.pageOptions.pageBodyScrollTop
}},[_c('Header'),(_vm.pageOptions.pageWithTopMenu)?_c('TopMenu'):_vm._e(),(!_vm.pageOptions.pageWithoutSidebar)?_c('Sidebar'):_vm._e(),(_vm.pageOptions.pageWithTwoSidebar)?_c('SidebarRight'):_vm._e(),_c('div',{staticClass:"content",class:{ 'content-full-width': _vm.pageOptions.pageContentFullWidth, 'content-inverse-mode': _vm.pageOptions.pageContentInverseMode },attrs:{"id":"content"}},[_c('router-view'),_c('vue-ins-progress-bar')],1),(_vm.pageOptions.pageWithFooter)?_c('Footer'):_vm._e(),_c('b-toast',{attrs:{"id":"notifToast","variant":"success","solid":"","auto-hide-delay":"6000"},scopedSlots:_vm._u([{key:"toast-title",fn:function(){return [_c('div',{staticClass:"d-flex flex-grow-1 align-items-baseline"},[_c('b-img',{staticClass:"mr-2",attrs:{"blank":"","blank-color":"#ff5555","width":"12","height":"12"}}),_c('strong',{staticClass:"mr-auto"},[_vm._v("Berhasil!")])],1)]},proxy:true}],null,false,3862020358)},[_vm._v(" Pembayaran dengan "),_c('br'),_vm._v(" No. SPT: "+_vm._s(_vm.no_spt)+" telah divalidasi. ")])],1):_c('div',{staticClass:"h-100"},[_c('router-view'),_c('vue-ins-progress-bar')],1)}
var staticRenderFns = []

export { render, staticRenderFns }