<template>
	<div>
		<!-- begin #header -->
		<div id="header" class="header navbar-default">
			<!-- begin navbar-header -->
			<div class="navbar-header">
				<button type="button" class="navbar-toggle pull-left" v-on:click="toggleMobileRightSidebar" v-if="pageOptions.pageWithTwoSidebar">
					<span class="icon-bar"></span>
					<span class="icon-bar"></span>
					<span class="icon-bar"></span>
				</button>
				<router-link to="/dashboard" class="navbar-brand">
					<span class="px-2"><b-img :src="imageLogo" width="30"></b-img> </span><b><span class="text-success">E</span>-RETRIBUSI</b>
				</router-link>
				<button type="button" class="navbar-toggle pt-0 pb-0 mr-0 collapsed" v-on:click="toggleMobileTopMenu" v-if="pageOptions.pageWithTopMenu && !pageOptions.pageWithoutSidebar">
					<span class="fa-stack fa-lg text-inverse">
						<i class="far fa-square fa-stack-2x"></i>
						<i class="fa fa-cog fa-stack-1x"></i>
					</span>
				</button>
				<button type="button" class="navbar-toggle" v-on:click="toggleMobileTopMenu" v-if="pageOptions.pageWithTopMenu && pageOptions.pageWithoutSidebar">
					<span class="icon-bar"></span>
					<span class="icon-bar"></span>
					<span class="icon-bar"></span>
				</button>
				<button type="button" class="navbar-toggle p-0 m-r-0" v-on:click="toggleMobileMegaMenu" v-if="pageOptions.pageWithMegaMenu">
					<span class="fa-stack fa-lg text-inverse m-t-2">
						<i class="far fa-square fa-stack-2x"></i>
						<i class="fa fa-cog fa-stack-1x"></i>
					</span>
				</button>
				<button type="button" class="navbar-toggle" v-on:click="toggleMobileSidebar" v-if="!pageOptions.pageWithoutSidebar">
					<span class="icon-bar"></span>
					<span class="icon-bar"></span>
					<span class="icon-bar"></span>
				</button>
			</div>
			<!-- end navbar-header -->
			
			<header-mega-menu v-if="pageOptions.pageWithMegaMenu"></header-mega-menu>

			<!-- begin header-nav -->
			<ul class="navbar-nav navbar-right">
				<div class="navbar-form">
					<button 
						class="btn btn-default btn-icon btn-lg mr-2" 
						@click="initFullScreen">
						<i class="fa fa-expand"></i>
					</button>
					<button 
						class="btn btn-default btn-icon btn-lg" 
						@click="reloadPage">
						<i class="fa fa-redo"></i>
					</button>
				</div>

				<b-nav-item-dropdown menu-class="dropdown-menu-right" class="dropdown navbar-user" no-caret>
					<template slot="button-content">
						<img :src="masinfo" alt="" /> 
						<span class="d-none d-md-inline"><span class="font-weight-bold">{{ user.name }}</span></span> <b class="caret"></b>
					</template>
					<b-dropdown-item to="/" style="width: 25vh;" right>
                        <b-row>
                            <b-col md="2" class="my-2">
                                <i class="fa fa-user-circle fa-2x"></i>
                            </b-col>
                            <b-col>
                                Login sebagai:
                                <div class="h5 font-weight-bold text-success">
									{{ role[0] }}
								</div>
                            </b-col>
                        </b-row>
                    </b-dropdown-item>
					<b-dropdown-divider></b-dropdown-divider>
					<b-dropdown-item @click="logout">
						<i class="fa fa-power-off mx-2"></i> Log Out
					</b-dropdown-item>
				</b-nav-item-dropdown>
				<li class="divider d-none d-md-block" v-if="pageOptions.pageWithTwoSidebar"></li>
				<li class="d-none d-md-block" v-if="pageOptions.pageWithTwoSidebar">
					<a href="javascript:;" v-on:click="toggleRightSidebarCollapsed" class="f-s-14">
						<i class="fa fa-th"></i>
					</a>
				</li>
			</ul>
			<!-- end header navigation right -->
		</div>
		<!-- end #header -->
	</div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars
import PageOptions from '../../config/PageOptions.vue'
import HeaderMegaMenu from './HeaderMegaMenu.vue'
import Logo from '@/assets/images/logoSumut.png'
import Masinfo from '@/assets/images/user.png'
import axios from 'axios'

export default {
	name: 'Header',
	components: {
		HeaderMegaMenu
	},
	data() {
			return {
				pageOptions: PageOptions,
				imageLogo: Logo,
				masinfo: Masinfo,
				user: JSON.parse(localStorage.getItem('user')),
				role: JSON.parse(localStorage.getItem('roles'))
			}
	},
	methods: {
		toggleMobileSidebar() {
			this.pageOptions.pageMobileSidebarToggled = !this.pageOptions.pageMobileSidebarToggled;
		},
		toggleMobileRightSidebar() {
			this.pageOptions.pageMobileRightSidebarToggled = !this.pageOptions.pageMobileRightSidebarToggled;
		},
		toggleMobileTopMenu() {
			this.pageOptions.pageMobileTopMenu = !this.pageOptions.pageMobileTopMenu;
		},
		toggleMobileMegaMenu() {
			this.pageOptions.pageMobileMegaMenu = !this.pageOptions.pageMobileMegaMenu;
		},
		toggleRightSidebar() {
			this.pageOptions.pageRightSidebarToggled = !this.pageOptions.pageRightSidebarToggled;
		},
		toggleRightSidebarCollapsed() {
			this.pageOptions.pageRightSidebarCollapsed = !this.pageOptions.pageRightSidebarCollapsed;
		},
		checkForm: function(e) {
			e.preventDefault();
			this.$router.push({ path: '/extra/search' })
		},
		logout() {
			axios.get("/api/logout").then(response => {
				localStorage.clear()
				this.$router.push('/auth/login')
			}).catch(error => {
				console.log(error)
			})
		// ---
		// this.$cookies.remove('XSRF-TOKEN' [path [domain]])
		// this.$cookies.remove('tngl_simaksi_session' [path [domain]])
		// this.$cookies.remove('XSRF-TOKEN')
		// this.$cookies.remove('tngl_simaksi_session')
		},

		/**
		 * WEB SETTING
		 * --------------------------------------------------
		 */
		// reload page ...
		reloadPage(){
			location.reload();
		},
		// fullscreen ...
		initFullScreen() {
			document.body.classList.toggle("fullscreen-enable");
			if (
				!document.fullscreenElement &&
				/* alternative standard method */ !document.mozFullScreenElement &&
				!document.webkitFullscreenElement
			) {
				// current working methods
				if (document.documentElement.requestFullscreen) {
				document.documentElement.requestFullscreen();
				} else if (document.documentElement.mozRequestFullScreen) {
				document.documentElement.mozRequestFullScreen();
				} else if (document.documentElement.webkitRequestFullscreen) {
				document.documentElement.webkitRequestFullscreen(
					Element.ALLOW_KEYBOARD_INPUT
				);
				}
			} else {
				if (document.cancelFullScreen) {
				document.cancelFullScreen();
				} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
				} else if (document.webkitCancelFullScreen) {
				document.webkitCancelFullScreen();
				}
			}
		},
	},
}
</script>
