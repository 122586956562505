export default {
    namespaced: true,
    state: {
        tabIndex: 0,
        perorangan: false,
        key: 0
    },
    mutations: {
        nextTab (state) {
            state.tabIndex++
        },
        prevTab (state) {
            state.tabIndex--
        },
        resetTab (state, value) {
            state.tabIndex = value
        },
        inputPerorangan(state, value) {
            state.perorangan = value
        },
        inputKey (state) {
            state.key = !state.key 
        }
    },
}