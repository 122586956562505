import axios from 'axios'
import router from '@/router'

export default {
    namespaced: true,
    state: {
        statusAplikasiOptions: [],
        ssrStatusAplikasiOptions: []
    },
    mutations: {
        inputStatusAplikasi (state, data) {
            state.statusAplikasiOptions = data
        },
        inputSsrStatusAplikasi (state, data) {
            state.ssrStatusAplikasiOptions = data
        }
    },
    actions: {
        async dataStatusAplikasi ({ commit }) {
           try {
               let response = await axios.get('/api/pengaturan/status-spt/getoption')
               commit("inputStatusAplikasi", response.data.data)
           } catch (error) {
               console.log(error)
               if (error.response.status === 401) {
                    localStorage.clear()
                    router.push({ name: 'Login' })
                }
           }
        },
        async dataSsrStatusAplikasi ({ commit }) {
            try {
                let response = await axios.get('/api/pengaturan/status-ssr/getoption')
                commit("inputSsrStatusAplikasi", response.data.data)
            } catch (error) {
                if (error.response.status === 401) {
                     localStorage.clear()
                     router.push({ name: 'Login' })
                 }
            }
         }
    }
}