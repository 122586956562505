import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
    // base: process.env.VUE_APP_BASE_URL,
    // Use the HTML5 history API (i.e. normal-looking routes)
    // instead of routes with hashes (e.g. example.com/#/about).
    // This may require some server configuration in production:
    // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
    // mode: 'history',
    mode: "hash", // https://router.vuejs.org/api/#mode
    // linkActiveClass: 'open active',
    // Simulate native-like scroll behavior when navigating to a new
    // route and using back/forward buttons.
    // scrollBehavior() {
    //     return window.scrollTo({ top: 0, behavior: 'smooth' });
    // },
    // or ...
    scrollBehavior: () => ({
        y: 0,
    }),
    // or ...
    // scrollBehavior(to, from, savedPosition) {
    //     if (savedPosition) {
    //     return savedPosition
    //     } else {
    //     return { x: 0, y: 0 }
    //     }
    // },
    routes,
})

// default title
const DEFAULT_TITLE = 'E-RETRIBUSI'

router.beforeEach((to, from, next) => {
    document.title = 'E-Retribusi | ' + to.meta.title || DEFAULT_TITLE;
    
    if(to.matched.some(record => record.meta.requiresAuth)) {
        if (localStorage.getItem('ER_token') == null) {
        next({
            path: '/auth/login',
            params: { nextUrl: to.fullPath }
        })
        } else {
        // let user = JSON.parse(localStorage.getItem('user'))
        // if(to.matched.some(record => record.meta.is_admin)) {
        //   if(user.is_admin == 1){
        //       next()
        //   }
        //   else{
        //       //next({ name: 'userboard'})
        //   }
        // }
        // else if(to.matched.some(record => record.meta.is_user)) {
        //   if(user.is_admin == 0){
        //       next()
        //   }
        //   else{
        //       next({ name: 'admin'})
        //   }
        // }
            // block route berdasarkan role
            // const role = JSON.parse(localStorage.getItem('roles'))[0]
            // if (!to.matched.some(record => record.meta.role.includes(role))){
            //     next({name: 'Page404'})
            // } else {
            //     next()
            // }

            next()
        }
    } else {
        next() 
    }
    })

export default router