import axios from 'axios'
import router from '@/router'

export default {
    namespaced: true,
    state: {
        perangkat: [],
        unit: []
    },
    mutations: {
        inputPerangkat (state, data) {
            state.perangkat = data
        },
        inputUnit (state, data) {
            state.unit = data
        }
    },
    actions: {
       getPerangkat ({ commit }) {
            axios.get("/api/registrasi/getoption/perangkat-pd").then(response => {
                commit('inputPerangkat', response.data.data)
            }).catch(error => {
                console.log(error)
                if (error.response.status === 401) {
                    localStorage.clear()
                    router.push({ name: 'Login' })
                }
            })
        },
        getUnit ({ commit }, id) {
            axios.get("/api/registrasi/getoption/unit-pd/perangkat-pd-" +id).then(response => {
                commit('inputUnit', response.data.data)
            }).catch(error => {
                console.log(error)
                if (error.response.status === 401) {
                    localStorage.clear()
                    router.push({ name: 'Login' })
                }
            })
        }
    }
}
