import axios from 'axios'

export default {
    namespaced: true,
    state: {
        role: []
    },
    mutations: {
        inputRole (state, data) {
            state.role = data
        }
    },
    actions: {
        getRole ({ commit }) {
            axios.get("/api/manajemen/role/getoption").then(response => {
                // console.log(response.data.data)
                commit("inputRole", response.data.data)
            }).catch(error => {
                console.log(error)
            })
        }
    }
}